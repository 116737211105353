<template>
    <div class="login-bg">
        <div class="p-d-flex p-jc-center">
            <Card style="width: 25em" class="p-mt-5">
                <template #header>
                    <div class="p-d-flex p-jc-center p-mt-4">
                        <img alt="user header" src="assets/layout/images/neofase_logo_login.jpg" style="width: 18em" />
                    </div>
                </template>
                <template #content>
                    <erros-box :erros="erros"></erros-box>
                    <div v-if="!solicitacaoOK">
                        <div class="p-formgrid p-grid p-fluid">
                            <div class="p-field p-col-12 p-mt-2">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-user"></i>
                                    </span>
                                    <InputText type="text" v-model="usuario" @input="v$.usuario.$touch()" placeholder="Usuário" />
                                </div>
                                <small class="p-error" v-if="v$.usuario.$error">O usuário é obrigatório</small>
                            </div>
                            <div class="p-field p-col-12 p-mt-2">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <font-awesome-icon icon="at" />
                                    </span>
                                    <InputText type="text" v-model="email" @input="v$.email.$touch()" placeholder="Email" />
                                </div>
                                <small class="p-error" v-if="v$.email.$error">O email é obrigatório</small>
                            </div>
                        </div>
                        <div class="p-col-12 p-d-flex p-jc-center">
                            <div>
                                <Button label="Recuperar Senha" icon="pi pi-check" @click="recaptcha" :disabled="v$.$invalid" />
                                <btn-voltar route="/login" class="p-mt-3"></btn-voltar>
                            </div>
                        </div>
                    </div>
                    <div v-if="solicitacaoOK">
                        <Message severity="success" :closable="false"
                            >Solicitação de nova senha realizada com sucesso. Se as informações fornecidas estiverem corretas você receberá um email com uma nova senha provisória para acesso ao sistema</Message
                        >
                        <div class="p-col-12 p-d-inline-flex p-flex-column p-jc-center">
                            <Button label="Reiniciar" icon="pi pi-check" @click="solicitacaoOK = false" class="btnLogin" />
                        </div>
                    </div>
                    <div class="p-mt-4 p-text-center">
                        <strong>v{{ versao }}</strong>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import AuthServices from './AuthServices';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            usuario: null,
            email: null,
            erros: [],
            versao: process.env.VUE_APP_VERSION,
            solicitacaoOK: false,
        };
    },

    validations() {
        return {
            usuario: { required },
            email: { required, email },
        };
    },

    methods: {
        async recaptcha() {
            this.v$.$touch();
            if (this.v$.$invalid) return;
            this.$store.dispatch('addRequest');
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.recuperarSenha(token);
        },

        reset() {
            this.usuario = null;
            this.email = null;
            this.$nextTick(() => {
                this.v$.$reset();
            });
        },

        recuperarSenha(token) {
            let recuperarSenhaDto = {
                usuario: this.usuario,
                email: this.email,
                token: token,
            };

            AuthServices.recuperarSenha(recuperarSenhaDto).then((response) => {
                if (response && response.success) {
                    this.reset();
                    this.solicitacaoOK = true;
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
